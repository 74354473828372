@import '../../assets/styles/style.scss';
@import '../../assets/styles/_variables';

#to {
  .header {
    background: linear-gradient(to bottom, rgba($colorPrimary, 0.5), rgba($colorPrimary, 0.5)), url('https://lifestyle-limo.com/wp-content/uploads/2019/07/Yukon-Denali-Full-1-1285x600.jpg') center / cover;
    padding: 8rem 0;

    * {
      color: #FFF;
    }

    .lead {
      margin-bottom: 0;
    }

    .form {
      text-align: center;
    }
  }

  .nav.nav-tabs {
    border: 0;
    border-bottom: 1px solid $colorLight;
    justify-content: center;

    .nav-item {
      align-items: center;
      border: 1px solid $colorLight;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      height: 100px;
      justify-content: center;
      text-decoration: none;
      width: 200px;

      &:hover {
        background-color: $colorLight;
      }

      &.active {
        background-color: $colorLight !important;
        color: $colorPrimary !important;
      }
    }
  }
}
