@import '../../assets/styles/_variables';

#about {
  .content {
    img {
      max-width: 4.6rem;
      height: auto;
      @media (max-width: 576px) {
        max-width: 50px;
      }
    }
    p {
      &.lead {
        border-left: .25rem solid $colorSecondary;
        padding-left: 1rem;
      }
    }
  }

  .values {
    .row:first-of-type {
      img {
        margin-top: 0;
      }
    }

    img {
      display: block;
      height: auto;
      margin: 2rem auto 0 auto;
      object-fit: cover;
      object-position: center;
      width: 75%;
    }
  }
}
