@import '../../assets/styles/_variables';

.usp img {
  object-position: center !important;
}


@media (max-width: 731px){
  .usp img {
    object-position: top !important;
    height:auto !important;
    float:unset !important;
    padding:5px 0;
  }
  .h2, h2 {
    font-size: 1.5rem;
    padding:10px 0 0;
  }
  p {
    font-size: 1rem;
  }
  .usp .row{
  flex-direction: column;
  text-align: center;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: center;
  -ms-flex-pack: start;
  justify-content: center;
  position: relative;
  }
  .usp .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
}
}
