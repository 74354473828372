@import '../../assets/styles/style.scss';

    .tour-card {
        display: block;
        margin-bottom: 1.5rem;
        border-color: #edf2f9;
        box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
        position: relative;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid #edf2f9;
        border-radius: .5rem;
        min-height:440px;
    }
    .tour-card:hover{
        box-shadow: 0 1rem 3rem rgba(18,38,63,.2);
        cursor:pointer;
    }
    .tour-card.show-above:hover{
        box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
        cursor: auto;
    }
    .tour-card-img-top {
        width: 100%;
        border-top-left-radius: calc(.5rem - 1px);
        border-top-right-radius: calc(.5rem - 1px);
    }
    .tour-card-body {
        flex: 1 1 auto;
        padding: 1.5rem;
    }
    .align-items-center {
        align-items: center!important;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -12px;
        margin-left: -12px;
    }
    .popup-overlay{
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        z-index: 999;
    }
    .show-above{
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 10000;
        max-width:600px;
        margin: 0 auto;
        max-height:95%;
        margin-top:1%;
    }
    .show-above img{
        z-index:100000;
        max-height: 280px;
        border-radius: .5rem .5rem 0 0;
        margin-bottom: 10px;
    }
    .tour-card-sa-body {
        overflow-y: scroll;
        height:calc(100% - 290px);
        padding:0 10px;
    }
    .slide{
        opacity:1;
        position:relative;
    }

    .close-card {
        position:fixed;
        top:10px;
        right:10px;
        border-radius:50%;
        background:#fff;
        width:25px;
        height:25px;
        text-align: center;
        z-index:100000;
    }
    @media (max-width: 731px){
        .tour-card {
            border-radius: 0rem;
        }
        .show-above{
            width:100%;
            margin: 0 auto;
            height:100%;
            max-height: 100%;
        }

       .show-above img{
        z-index:100000;
        max-height: 280px;
        border-radius: 0rem 0rem 0 0;
        }

        .tour-card-sa-body {
            overflow-y: scroll;
            height:100%;
            padding:0 10px;
            padding-bottom:280px;
        }
    }
    .ScrollUpButton__Container{
        position:fixed;
        top:0;
        z-index: 100;
        opacity:0;
        text-align:center;
        left:0;
        right:0;
        width:250px;
        background:#E0297C;
        margin:0 auto;
        padding:5px 0px;
        border-radius: 0px 0px 5px 5px;

    }
    .ScrollUpButton__Toggled {
        opacity:1;
    }

#fullscreen-header {
    background-size: cover;
    background-repeat: no-repeat;
}

.booking-form-fixed #bookingform {
    max-height: 70vh;
    overflow-y: scroll;
    position: sticky;
    top: calc(50vh - 35vh + 54px);
}

.tours-slider {
    .slick-slide {
        width: 50vw;
    }
    .controls {
        .slick-prev::before, .slick-next::before {
            color: $yellow;
        }
    }
    .slick-list {
        display: flex;
        align-items: stretch;
        .slick-slide > div {
            height: 100%;
            & > div {
                height: 100%;
                & > div {
                    height: 100%;
                    .card-body {
                        height: 100%;
                    }
                }
            }
        }
    }
}

#destination {
    .benefits > div:last-child {
        margin-bottom: 0 !important;
    }
    .steps .col-6:last-child .step {
        margin-bottom: 3rem !important;
    }
    .steps .col-6:nth-child(odd) .step {
        margin-right: 0.25rem;
    }
    // .steps img {
    //     width: 2rem;
    //     @media (min-width: 767px) {
    //         width: 5rem;
    //     }
    // }
}

.dark-overlay {
    position: relative;
    .overlay-content {
        z-index: 2;
        position: relative;
    }
    &::before {
        content: '';
        position: absolute;
        display: block;
        background-color: black;
        opacity: 0.45;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}