@import '../../assets/styles/_variables';

.navbar {
  padding: 0.2rem !important;
  a {
    text-decoration: none;
  }
  .centered {
    text-align: center;
    display: flex;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    text-align: center;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: center;
    -ms-flex-pack: start;
    justify-content: center;
    position: relative;
  }
  .row {
    padding: 0;
    width: 100%;
  }
  .navbar-toggle {
    float:right;
  }
  .navbar-nav {
    margin-left: auto;

    .nav-link {
      color: $colorDark !important;
      font-family: $primaryFont;
      font-size: .9rem;
      letter-spacing: -.05rem;
      margin: 0 .5rem;

      &:hover {
        color: $colorSecondary !important;
      }

      &.active {
        color: $colorSecondary !important;
      }
    }
  }
}


 @media (max-width: 731px){
   .centered.nav-links {
     -webkit-box-align: flex-start;
     -ms-flex-align: flex-start;
     align-items: flex-start;
     text-align: center;
   }
 }
