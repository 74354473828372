@import url('https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap');

$primaryFont: 'Comfortaa', sans-serif;
// $secondaryFont: 'Frank Ruhl Libre', serif;
// $secondaryFont: 'Open Sans', sans-serif;
$secondaryFont: 'Athiti', sans-serif;

$colorPrimary: #4692E4;
$colorPrimaryDarken: darken($colorPrimary, 10%);

$colorSecondary: #E0297C;
$colorSecondaryDarken: darken($colorSecondary, 10%);

$colorDark: #21282b;
$colorLight: #F8F9FA;

$colorText: lighten($colorDark, 20%);

$yellow: #FDB515;