.partners-form.show-above {
    max-width: 400px;
    height: fit-content;
    top: 50%;
    transform: translateY(-50%);
    .form-row {
        margin-bottom: 20px;
        select {
            -o-appearance: none;
            -ms-appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
         }
        .form-group {
            width: 100%;
            margin-bottom: 0;
        }
        input, select {
            width: 100%;
            padding: 12px;
            border: 1px solid rgb(206, 212, 218);
            border-radius: 4px;
        }
        select {
            background: #fff url(../../assets/images/arrow_down.svg);
            background-repeat: no-repeat;
            background-position: right 12px center;
        }
    }
}

.partners-form {
    .form-control {
        height: auto;
    }
}