#privacy {
  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
    text-transform: capitalize;
  }

  h3 {
    font-size: 1.4rem;
    margin: 1rem 0 .5rem 0;
    text-transform: capitalize;
  }

  p {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
