@import '../../assets/styles/variables';
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

#tour-page-header {
    min-height: calc(100vh - 54px);
    background-size: cover;
    background-position: center;
    p.title {
        font-size: 2.5rem;
    }
}

.booking-form-fixed .tour-form {
    // max-height: 70vh;
    min-height: 300px;
    // overflow-y: scroll;
    width: 100%;
    position: fixed;
    top: calc(50% + 54px);
    transform: translateY(-50%);
    max-width: 350px;
}

.reviews-section {
    background-color: darken($colorLight, 10%);
    .carousel-item {
        max-width: fit-content;
        display: block;
    }
}

.truncate-lines-2 {
    -webkit-line-clamp: 2;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.book-now {
    text-decoration: none;
    color: #E0297C;
    font-weight: bold;
}

.slick-center {
    transform: scale(1.1);
    margin-left: 2rem;
    margin-right: 2rem;
    transition: .5s ease-in-out;
}

.slick-slide {
    transition: .5s ease-in-out;
}

#tours-page .tour-card-img-top{
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

#tours-page {
    .tour-card-item {
        cursor: pointer;
        box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03); 
        &:hover {
            box-shadow: 0 1rem 3rem rgba(18, 38, 63, 0.2);
        }
        .row {
            max-width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }
    .tour-card-sa-body {
        a {
            text-decoration: none;
            font-weight: bold;
        }
    }
}

.slick-track {
    display: flex;
    align-items: center;
}

.slick-slide {
    padding: 0 0.6rem;
}


.close-card {
    cursor: pointer;
}

.tour-form {
    height: fit-content;
    .form-control {
        height: auto;
    }
    .form-row {
        margin-bottom: 20px;
        select {
            -o-appearance: none;
            -ms-appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
         }
        .form-group {
            width: 100%;
            margin-bottom: 0;
        }
        input, select {
            width: 100%;
            padding: 12px;
            border: 1px solid rgb(206, 212, 218);
            border-radius: 4px;
        }
        .select-wrapper::after {
            content: '';
            position: absolute;
            background: #fff url(../../assets/images/arrow_down.svg);
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
        }
        .select-wrapper {
            position: relative;
        }
        // select {
        //     background: #fff url(../../assets/images/arrow_down.svg);
        //     background-repeat: no-repeat;
        //     background-position: right 12px center;
        // }
    }
}

// .text-background-dark {
//     display: inline;
//     background-color: rgba(0,0,0,0.4);
// }

.tour-card-item:last-child {
    margin-bottom: 0 !important;
}

.fixed-form-container {
    @media (min-width: 992px) {
        position: absolute;
        top: calc(-3rem - 80vh + 54px);
        right: 0;
        height: calc(100% + 80vh + 3rem - 54px);
        z-index: 10;
        .tour-form {
            position: sticky;
            z-index: 10;
            top: calc(50vh - 233px + 54px);
        }
    }
}

.card-img-absolute {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
}