@import '../../assets/styles/style.scss';

.accordion {
  .card {
    background: none;

    .card-header {
      background: $colorLight;

      button {
        background: transparent;
        color: $colorSecondary;
        padding: 0;
        text-decoration: none;
        text-align:left;
      }
    }
  }
}
