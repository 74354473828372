@import '../../assets/styles/_variables';

.footer {
  background-color: $colorDark;
  padding-top: 3rem;
  padding-bottom: 3rem;

  .fb-page.fb_iframe_widget {
    max-width: 100% !important;
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $colorLight !important;
  }

  p {
    color: #FFF;
  }

  a {
    color: #FFF;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;

      a {
        padding-left: 0;
      }
    }
  }
}
