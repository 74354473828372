@import '../../assets/styles/_variables';

#page-header {
  background: $colorDark;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  text-align: left;

  * {
    color: #FFF;
  }

  .lead {
    margin-bottom: 0;
  }

  .form {
    text-align: center;
  }

  .image {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: 1;
  }

  @media (max-width: 576px) {
    .bg-image {
      transform: scaleX(-1) !important;
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(270deg,rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.3) 73%,rgba(0,0,0,0.48) 86%,#000 100%);
  }

  @media (min-width: 576px) {
    .image {
      height: 100%;
      mask: url('../../assets/images/PageHeaderClipPath.svg');
      -webkit-mask: url('../../assets/images/PageHeaderClipPath.svg');
      mask-size: cover;
      -webkit-mask-size: cover;
      mask-position: center left;
      -webkit-mask-position: center left;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      object-fit: cover;
      object-position: 50% 25%;
      position: absolute;
      right: 0;
      top: 0;
      width: 60%;
    }
    &::before {
      display: none;
    }
  }
  
}


@media (min-width: 576px) and (max-width: 731px){

  #page-header {
    .image {
      width:30% !important;
    }

}
}

#fullscreen-header {
  min-height: calc(80vh - 54px);
  background-size: cover;
  background-position: center;
  // background-color: #D2691E;
  p.title {
      font-size: 2.5rem;
  }
}

.text-below-button {
  font-size: 12px;
  color: white;
  margin-top: 4px;
  max-width: 216px;
  text-align: center;
}

// .image-background-container {
//   position: absolute;
//   width: 100%;
//   z-index: 0;
//   top: 0;
//   left: 0;
//   height: 100%;
//   object-fit: cover;
//   span, image {
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//   }
// }