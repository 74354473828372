.StripeCheckout .verifybtn {
    overflow: hidden; display: inline-block; background: linear-gradient(rgb(40, 160, 229), rgb(1, 94, 148)); border: 0px; padding: 1px; text-decoration: none; border-radius: 5px; box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px; cursor: pointer; visibility: visible; user-select: none;
}
.StripeCheckout .verifybtn span{
    background-image: linear-gradient(rgb(125, 197, 238), rgb(0, 140, 221) 85%, rgb(48, 162, 228)); font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 14px; position: relative; padding: 0px 12px; display: block; height: 30px; line-height: 30px; color: rgb(255, 255, 255); font-weight: bold; box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset; text-shadow: rgba(0, 0, 0, 0.25) 0px -1px 0px; border-radius: 4px;
}
.react-datepicker__input-container input{
    width:100% !important;
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;
    height: calc(1.5em + .75rem + 2px) !important;
    padding: .375rem .75rem !important;
}
.react-datepicker-wrapper {
    width:100%;
}


/*
 * Aloglia Styling 
 */

 h1 {
    margin-bottom: 1rem;
  }
  
  em {
    background: #ffe8e0;
    font-style: normal;
  }
  
  .ais-InstantSearch__root {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 13px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    box-sizing: border-box;
    top: 51px;
    width: 100%;
    border: 1px solid rgb(100, 100, 100);
    background-color: #fff;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__section-title {
    padding: 10px 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: Lato, sans-serif;
    font-family: Lato, sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: rgb(68, 68, 68);
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: rgb(240, 240, 240);
  }
  