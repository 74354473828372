@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:700|Frank+Ruhl+Libre:300|Open+Sans:300|Athiti:400&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
.navbar {
  padding: 0.2rem !important; }
  .navbar a {
    text-decoration: none; }
  .navbar .centered {
    text-align: center;
    display: flex;
    align-items: flex-end;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    position: relative; }
  .navbar .row {
    padding: 0;
    width: 100%; }
  .navbar .navbar-toggle {
    float: right; }
  .navbar .navbar-nav {
    margin-left: auto; }
    .navbar .navbar-nav .nav-link {
      color: #21282b !important;
      font-family: "Comfortaa", sans-serif;
      font-size: .9rem;
      letter-spacing: -.05rem;
      margin: 0 .5rem; }
      .navbar .navbar-nav .nav-link:hover {
        color: #E0297C !important; }
      .navbar .navbar-nav .nav-link.active {
        color: #E0297C !important; }

@media (max-width: 731px) {
  .centered.nav-links {
    align-items: flex-start;
    text-align: center; } }

.footer {
  background-color: #21282b;
  padding-top: 3rem;
  padding-bottom: 3rem; }
  .footer .fb-page.fb_iframe_widget {
    max-width: 100% !important;
    overflow: hidden; }
  .footer h1, .footer h2, .footer h3, .footer h4, .footer h5, .footer h6 {
    color: #F8F9FA !important; }
  .footer p {
    color: #FFF; }
  .footer a {
    color: #FFF;
    text-decoration: none; }
  .footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .footer ul li {
      margin: 0;
      padding: 0; }
      .footer ul li a {
        padding-left: 0; }

* {
  font-family: "Athiti", sans-serif; }

a {
  color: #4d5e65;
  font-family: "Athiti", sans-serif;
  text-decoration: underline; }
  a:hover {
    color: #E0297C;
    text-decoration: none; }

html, body {
  max-width: 100vw; }

.bg-primary {
  background-color: #E0297C !important; }

.btn {
  border-radius: .25rem;
  font-family: "Comfortaa", sans-serif;
  font-size: .9rem;
  padding: .5rem 4rem;
  text-decoration: none; }
  .btn.btn-primary {
    background-color: #E0297C !important;
    border-color: #E0297C !important; }
    .btn.btn-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:focus {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important; }
  .btn.btn-outline-primary {
    background-color: transparent !important;
    border-color: #E0297C !important;
    color: #E0297C !important; }
    .btn.btn-outline-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important;
      color: #FFF !important; }
    .btn.btn-outline-primary:focus {
      background-color: transparent !important;
      border-color: #E0297C !important;
      box-shadow: none !important;
      color: #E0297C !important; }
    .btn.btn-outline-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      color: #FFF !important; }
  .btn.outline-secondary {
    background-color: transparent;
    border-color: #21282b;
    color: #21282b; }
    .btn.outline-secondary:hover {
      background-color: #21282b !important;
      color: #FFF; }
  .btn.btn-white {
    background-color: #FFF;
    border-color: #FFF;
    color: #E0297C; }
    .btn.btn-white:hover {
      background-color: #E0297C;
      border-color: #E0297C;
      color: #FFF; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #21282b;
  font-family: "Comfortaa", sans-serif;
  letter-spacing: -.1rem; }

p {
  color: #4d5e65;
  font-family: "Athiti", sans-serif; }

.text-primary {
  color: #E0297C !important; }

.bg-dark-gray {
  background-color: #21282b; }

.popup-overlay.background-blur {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); }

.text-yellow {
  color: #FDB515; }

.bg-yellow {
  background-color: #FDB515; }

.gradient-yellow {
  background: linear-gradient(to right, #FDB515, 75%, white); }

.ScrollUpButton__Container {
  pointer-events: none; }
  .ScrollUpButton__Container.ScrollUpButton__Toggled {
    pointer-events: all; }

div.wh-widget-send-button-wrapper.wh-widget-right div.wh-widget-send-button-wrapper-list a.wh-widget-send-button-get-button {
  display: none !important; }

#App {
  margin: 0 auto;
  max-width: 1920px; }

* {
  font-family: "Athiti", sans-serif; }

a {
  color: #4d5e65;
  font-family: "Athiti", sans-serif;
  text-decoration: underline; }
  a:hover {
    color: #E0297C;
    text-decoration: none; }

html, body {
  max-width: 100vw; }

.bg-primary {
  background-color: #E0297C !important; }

.btn {
  border-radius: .25rem;
  font-family: "Comfortaa", sans-serif;
  font-size: .9rem;
  padding: .5rem 4rem;
  text-decoration: none; }
  .btn.btn-primary {
    background-color: #E0297C !important;
    border-color: #E0297C !important; }
    .btn.btn-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:focus {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important; }
  .btn.btn-outline-primary {
    background-color: transparent !important;
    border-color: #E0297C !important;
    color: #E0297C !important; }
    .btn.btn-outline-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important;
      color: #FFF !important; }
    .btn.btn-outline-primary:focus {
      background-color: transparent !important;
      border-color: #E0297C !important;
      box-shadow: none !important;
      color: #E0297C !important; }
    .btn.btn-outline-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      color: #FFF !important; }
  .btn.outline-secondary {
    background-color: transparent;
    border-color: #21282b;
    color: #21282b; }
    .btn.outline-secondary:hover {
      background-color: #21282b !important;
      color: #FFF; }
  .btn.btn-white {
    background-color: #FFF;
    border-color: #FFF;
    color: #E0297C; }
    .btn.btn-white:hover {
      background-color: #E0297C;
      border-color: #E0297C;
      color: #FFF; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #21282b;
  font-family: "Comfortaa", sans-serif;
  letter-spacing: -.1rem; }

p {
  color: #4d5e65;
  font-family: "Athiti", sans-serif; }

.text-primary {
  color: #E0297C !important; }

.bg-dark-gray {
  background-color: #21282b; }

.popup-overlay.background-blur {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); }

.text-yellow {
  color: #FDB515; }

.bg-yellow {
  background-color: #FDB515; }

.gradient-yellow {
  background: linear-gradient(to right, #FDB515, 75%, white); }

.ScrollUpButton__Container {
  pointer-events: none; }
  .ScrollUpButton__Container.ScrollUpButton__Toggled {
    pointer-events: all; }

div.wh-widget-send-button-wrapper.wh-widget-right div.wh-widget-send-button-wrapper-list a.wh-widget-send-button-get-button {
  display: none !important; }

#city {
  position: relative; }
  #city .content {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: .5rem;
    color: #FFF;
    display: flex;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 90%; }
    #city .content:hover {
      background: rgba(0, 0, 0, 0); }

@media (max-width: 731px) {
  #city .content {
    width: 100%; }
  .cities img {
    border-radius: .4rem;
    height: 125px;
    object-fit: cover;
    object-position: center;
    width: 100%; }
  .cities .col, .cities .col-1, .cities .col-10, .cities .col-11, .cities .col-12, .cities .col-2, .cities .col-3, .cities .col-4, .cities .col-5, .cities .col-6, .cities .col-7, .cities .col-8, .cities .col-9, .cities .col-auto, .cities .col-lg, .cities .col-lg-1, .cities .col-lg-10, .cities .col-lg-11, .cities .col-lg-12, .cities .col-lg-2, .cities .col-lg-3, .cities .col-lg-4, .cities .col-lg-5, .cities .col-lg-6, .cities .col-lg-7, .cities .col-lg-8, .cities .col-lg-9, .cities .col-lg-auto, .cities .col-md, .cities .col-md-1, .cities .col-md-10, .cities .col-md-11, .cities .col-md-12, .cities .col-md-2, .cities .col-md-3, .cities .col-md-4, .cities .col-md-5, .cities .col-md-6, .cities .col-md-7, .cities .col-md-8, .cities .col-md-9, .cities .col-md-auto, .cities .col-sm, .cities .col-sm-1, .cities .col-sm-10, .cities .col-sm-11, .cities .col-sm-12, .cities .col-sm-2, .cities .col-sm-3, .cities .col-sm-4, .cities .col-sm-5, .cities .col-sm-6, .cities .col-sm-7, .cities .col-sm-8, .cities .col-sm-9, .cities .col-sm-auto, .cities .col-xl, .cities .col-xl-1, .cities .col-xl-10, .cities .col-xl-11, .cities .col-xl-12, .cities .col-xl-2, .cities .col-xl-3, .cities .col-xl-4, .cities .col-xl-5, .cities .col-xl-6, .cities .col-xl-7, .cities .col-xl-8, .cities .col-xl-9, .cities .col-xl-auto {
    padding: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important; } }

.usp img {
  object-position: center !important; }

@media (max-width: 731px) {
  .usp img {
    object-position: top !important;
    height: auto !important;
    float: unset !important;
    padding: 5px 0; }
  .h2, h2 {
    font-size: 1.5rem;
    padding: 10px 0 0; }
  p {
    font-size: 1rem; }
  .usp .row {
    flex-direction: column;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    position: relative; }
  .usp .col-6 {
    flex: 0 0 100%;
    max-width: 100%; } }

.header-new {
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(/static/media/video_sito_optimized.8e34fcac.webp);
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  text-align: center; }
  .header-new h1 {
    color: #FFF; }
  .header-new .lead {
    color: #FFF; }

.header .header-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .header .header-content h1 {
    font-size: 4rem; }

.header .header-image img {
  height: 500px;
  object-fit: contain;
  object-position: center;
  width: 100%; }

.usp {
  background-color: #F8F9FA; }
  .usp img {
    float: left;
    height: 100%;
    margin-right: 1.5rem;
    object-fit: contain;
    object-position: top;
    width: 75px; }
    @media (max-width: 576px) {
      .usp img {
        width: 50px;
        align-self: center; } }

.cities {
  text-align: center; }
  .cities .title {
    margin-bottom: 2rem; }
  .cities img {
    border-radius: .4rem;
    height: 125px;
    object-fit: cover;
    object-position: center;
    width: 100%; }

.counter {
  background-color: #F8F9FA; }
  .counter .count {
    color: #E0297C;
    font-family: "Comfortaa", sans-serif !important;
    font-size: 2rem;
    font-weight: 700;
    text-align: center; }

.mockup-img {
  width: 74.6%;
  top: 6%;
  left: 13%; }

.laptop {
  position: absolute;
  width: 100%; }

.map {
  width: 85%;
  margin: 0 auto; }

.laptop-container, .laptop-container .col, .laptop-container .row {
  position: relative; }

.laptop-container .col {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; }

@media (max-width: 731px) {
  .header-new {
    min-height: 50vh;
    max-width: 100vw; }
  .h1, h1 {
    font-size: 2rem; }
  .lead {
    font-size: 1rem; }
  .laptop {
    width: 90%; }
  .map {
    width: 75%; }
  .counter .row {
    flex-direction: column; } }

.hero-background-video {
  position: absolute;
  top: 0;
  height: 100%;
  width: 177.77777778vh;
  /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw;
  /* 100 * 9 / 16 */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.hero-background-video {
  position: absolute;
  z-index: 0; }

.z-index-4 {
  z-index: 4; }

#homepage {
  max-width: 100vw;
  overflow-x: hidden; }

.circle-loader {
  margin-bottom: 3.5em;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  -webkit-animation: loader-spin 1.2s infinite linear;
          animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em; }

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out; }
  .load-complete > .checkmark.draw {
    display: block; }

.checkmark {
  display: none; }
  .checkmark.draw:after {
    -webkit-animation-duration: 800ms;
            animation-duration: 800ms;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-name: checkmark;
            animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg); }
  .checkmark:after {
    opacity: 1;
    height: 3.5em;
    width: 1.75em;
    transform-origin: left top;
    border-right: 6px solid #5cb85c;
    border-top: 6px solid #5cb85c;
    content: '';
    left: 1.75em;
    top: 3.5em;
    position: absolute; }

@-webkit-keyframes loader-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1; }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1; }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1; } }

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1; }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1; }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1; } }

.partners-form.show-above {
  max-width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 50%;
  transform: translateY(-50%); }
  .partners-form.show-above .form-row {
    margin-bottom: 20px; }
    .partners-form.show-above .form-row select {
      -o-appearance: none;
      -ms-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    .partners-form.show-above .form-row .form-group {
      width: 100%;
      margin-bottom: 0; }
    .partners-form.show-above .form-row input, .partners-form.show-above .form-row select {
      width: 100%;
      padding: 12px;
      border: 1px solid #ced4da;
      border-radius: 4px; }
    .partners-form.show-above .form-row select {
      background: #fff url(/static/media/arrow_down.d1aad0b7.svg);
      background-repeat: no-repeat;
      background-position: right 12px center; }

.partners-form .form-control {
  height: auto; }

#about .content img {
  max-width: 4.6rem;
  height: auto; }
  @media (max-width: 576px) {
    #about .content img {
      max-width: 50px; } }

#about .content p.lead {
  border-left: 0.25rem solid #E0297C;
  padding-left: 1rem; }

#about .values .row:first-of-type img {
  margin-top: 0; }

#about .values img {
  display: block;
  height: auto;
  margin: 2rem auto 0 auto;
  object-fit: cover;
  object-position: center;
  width: 75%; }

* {
  font-family: "Athiti", sans-serif; }

a {
  color: #4d5e65;
  font-family: "Athiti", sans-serif;
  text-decoration: underline; }
  a:hover {
    color: #E0297C;
    text-decoration: none; }

html, body {
  max-width: 100vw; }

.bg-primary {
  background-color: #E0297C !important; }

.btn {
  border-radius: .25rem;
  font-family: "Comfortaa", sans-serif;
  font-size: .9rem;
  padding: .5rem 4rem;
  text-decoration: none; }
  .btn.btn-primary {
    background-color: #E0297C !important;
    border-color: #E0297C !important; }
    .btn.btn-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:focus {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important; }
  .btn.btn-outline-primary {
    background-color: transparent !important;
    border-color: #E0297C !important;
    color: #E0297C !important; }
    .btn.btn-outline-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important;
      color: #FFF !important; }
    .btn.btn-outline-primary:focus {
      background-color: transparent !important;
      border-color: #E0297C !important;
      box-shadow: none !important;
      color: #E0297C !important; }
    .btn.btn-outline-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      color: #FFF !important; }
  .btn.outline-secondary {
    background-color: transparent;
    border-color: #21282b;
    color: #21282b; }
    .btn.outline-secondary:hover {
      background-color: #21282b !important;
      color: #FFF; }
  .btn.btn-white {
    background-color: #FFF;
    border-color: #FFF;
    color: #E0297C; }
    .btn.btn-white:hover {
      background-color: #E0297C;
      border-color: #E0297C;
      color: #FFF; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #21282b;
  font-family: "Comfortaa", sans-serif;
  letter-spacing: -.1rem; }

p {
  color: #4d5e65;
  font-family: "Athiti", sans-serif; }

.text-primary {
  color: #E0297C !important; }

.bg-dark-gray {
  background-color: #21282b; }

.popup-overlay.background-blur {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); }

.text-yellow {
  color: #FDB515; }

.bg-yellow {
  background-color: #FDB515; }

.gradient-yellow {
  background: linear-gradient(to right, #FDB515, 75%, white); }

.ScrollUpButton__Container {
  pointer-events: none; }
  .ScrollUpButton__Container.ScrollUpButton__Toggled {
    pointer-events: all; }

div.wh-widget-send-button-wrapper.wh-widget-right div.wh-widget-send-button-wrapper-list a.wh-widget-send-button-get-button {
  display: none !important; }

.accordion .card {
  background: none; }
  .accordion .card .card-header {
    background: #F8F9FA; }
    .accordion .card .card-header button {
      background: transparent;
      color: #E0297C;
      padding: 0;
      text-decoration: none;
      text-align: left; }

.StripeCheckout .verifybtn {
  overflow: hidden;
  display: inline-block;
  background: linear-gradient(#28a0e5, #015e94);
  border: 0px;
  padding: 1px;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px;
  cursor: pointer;
  visibility: visible;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.StripeCheckout .verifybtn span {
  background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  position: relative;
  padding: 0px 12px;
  display: block;
  height: 30px;
  line-height: 30px;
  color: white;
  font-weight: bold;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  text-shadow: rgba(0, 0, 0, 0.25) 0px -1px 0px;
  border-radius: 4px; }

.react-datepicker__input-container input {
  width: 100% !important;
  border: 1px solid #ced4da !important;
  border-radius: .25rem !important;
  height: calc(1.5em + .75rem + 2px) !important;
  padding: .375rem .75rem !important; }

.react-datepicker-wrapper {
  width: 100%; }

/*
 * Aloglia Styling 
 */
h1 {
  margin-bottom: 1rem; }

em {
  background: #ffe8e0;
  font-style: normal; }

.ais-InstantSearch__root {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__input {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid #aaa;
  border-radius: 4px; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 51px;
  width: 100%;
  border: 1px solid #646464;
  background-color: #fff;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2; }

.react-autosuggest__section-title {
  padding: 10px 20px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: Lato, sans-serif;
  font-family: Lato, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #444444; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.react-autosuggest__suggestion--highlighted {
  background-color: #f0f0f0; }

#page-header {
  background: #21282b;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  text-align: left; }
  #page-header * {
    color: #FFF; }
  #page-header .lead {
    margin-bottom: 0; }
  #page-header .form {
    text-align: center; }
  #page-header .image {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: 1; }
  @media (max-width: 576px) {
    #page-header .bg-image {
      transform: scaleX(-1) !important; } }
  #page-header::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 73%, rgba(0, 0, 0, 0.48) 86%, #000 100%); }
  @media (min-width: 576px) {
    #page-header .image {
      height: 100%;
      mask: url(/static/media/PageHeaderClipPath.8b7b3ee6.svg);
      -webkit-mask: url(/static/media/PageHeaderClipPath.8b7b3ee6.svg);
      mask-size: cover;
      -webkit-mask-size: cover;
      mask-position: center left;
      -webkit-mask-position: center left;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      object-fit: cover;
      object-position: 50% 25%;
      position: absolute;
      right: 0;
      top: 0;
      width: 60%; }
    #page-header::before {
      display: none; } }

@media (min-width: 576px) and (max-width: 731px) {
  #page-header .image {
    width: 30% !important; } }

#fullscreen-header {
  min-height: calc(80vh - 54px);
  background-size: cover;
  background-position: center; }
  #fullscreen-header p.title {
    font-size: 2.5rem; }

.text-below-button {
  font-size: 12px;
  color: white;
  margin-top: 4px;
  max-width: 216px;
  text-align: center; }

#partners .list-items {
  text-align: center; }
  #partners .list-items .title {
    margin: 1.5rem 0 .5rem 0; }
  #partners .list-items p {
    margin-bottom: 0; }
  #partners .list-items img {
    display: block;
    height: 100px;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    width: 100px; }

#partners .nav.nav-tabs {
  border: 0;
  border-bottom: 1px solid #F8F9FA;
  justify-content: center; }
  #partners .nav.nav-tabs .nav-item {
    align-items: center;
    border: 1px solid #F8F9FA;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    text-decoration: none;
    width: 200px; }
    #partners .nav.nav-tabs .nav-item:hover {
      background-color: #F8F9FA; }
    #partners .nav.nav-tabs .nav-item.active {
      background-color: #F8F9FA !important;
      color: #E0297C !important; }

#partners-page img, #about img {
  max-width: 100%;
  width: 100%; }

#partners-page #bookingform, #about #bookingform {
  display: none; }

#partners-page .popup-overlay, #about .popup-overlay {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); }

#partners-page .fixed-button-container, #about .fixed-button-container {
  position: fixed;
  bottom: 0;
  right: 100px;
  z-index: 100000000; }

#partners-page .btn, #about .btn {
  transition: 0.2s ease-in-out; }

#partners-page .btn-hidden, #about .btn-hidden {
  opacity: 0;
  transform: scale(0.25); }

#partners-page .partner-logo, #about .partner-logo {
  max-width: 250px; }

#partners-page .integrations-logo, #about .integrations-logo {
  max-height: 100px;
  width: auto;
  max-width: 300px; }

@media (max-width: 576px) {
  #partners-page .partner-benefits .partner-benefit:last-child, #about .partner-benefits .partner-benefit:last-child {
    order: -1; } }

#partners-page .partner-benefits img, #about .partner-benefits img {
  max-width: 75px; }
  @media (max-width: 576px) {
    #partners-page .partner-benefits img, #about .partner-benefits img {
      width: 60px;
      align-self: center; } }



* {
  font-family: "Athiti", sans-serif; }

a {
  color: #4d5e65;
  font-family: "Athiti", sans-serif;
  text-decoration: underline; }
  a:hover {
    color: #E0297C;
    text-decoration: none; }

html, body {
  max-width: 100vw; }

.bg-primary {
  background-color: #E0297C !important; }

.btn {
  border-radius: .25rem;
  font-family: "Comfortaa", sans-serif;
  font-size: .9rem;
  padding: .5rem 4rem;
  text-decoration: none; }
  .btn.btn-primary {
    background-color: #E0297C !important;
    border-color: #E0297C !important; }
    .btn.btn-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:focus {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important; }
  .btn.btn-outline-primary {
    background-color: transparent !important;
    border-color: #E0297C !important;
    color: #E0297C !important; }
    .btn.btn-outline-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important;
      color: #FFF !important; }
    .btn.btn-outline-primary:focus {
      background-color: transparent !important;
      border-color: #E0297C !important;
      box-shadow: none !important;
      color: #E0297C !important; }
    .btn.btn-outline-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      color: #FFF !important; }
  .btn.outline-secondary {
    background-color: transparent;
    border-color: #21282b;
    color: #21282b; }
    .btn.outline-secondary:hover {
      background-color: #21282b !important;
      color: #FFF; }
  .btn.btn-white {
    background-color: #FFF;
    border-color: #FFF;
    color: #E0297C; }
    .btn.btn-white:hover {
      background-color: #E0297C;
      border-color: #E0297C;
      color: #FFF; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #21282b;
  font-family: "Comfortaa", sans-serif;
  letter-spacing: -.1rem; }

p {
  color: #4d5e65;
  font-family: "Athiti", sans-serif; }

.text-primary {
  color: #E0297C !important; }

.bg-dark-gray {
  background-color: #21282b; }

.popup-overlay.background-blur {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); }

.text-yellow {
  color: #FDB515; }

.bg-yellow {
  background-color: #FDB515; }

.gradient-yellow {
  background: linear-gradient(to right, #FDB515, 75%, white); }

.ScrollUpButton__Container {
  pointer-events: none; }
  .ScrollUpButton__Container.ScrollUpButton__Toggled {
    pointer-events: all; }

div.wh-widget-send-button-wrapper.wh-widget-right div.wh-widget-send-button-wrapper-list a.wh-widget-send-button-get-button {
  display: none !important; }

#to .header {
  background: linear-gradient(to bottom, rgba(70, 146, 228, 0.5), rgba(70, 146, 228, 0.5)), url("https://lifestyle-limo.com/wp-content/uploads/2019/07/Yukon-Denali-Full-1-1285x600.jpg") center/cover;
  padding: 8rem 0; }
  #to .header * {
    color: #FFF; }
  #to .header .lead {
    margin-bottom: 0; }
  #to .header .form {
    text-align: center; }

#to .nav.nav-tabs {
  border: 0;
  border-bottom: 1px solid #F8F9FA;
  justify-content: center; }
  #to .nav.nav-tabs .nav-item {
    align-items: center;
    border: 1px solid #F8F9FA;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    text-decoration: none;
    width: 200px; }
    #to .nav.nav-tabs .nav-item:hover {
      background-color: #F8F9FA; }
    #to .nav.nav-tabs .nav-item.active {
      background-color: #F8F9FA !important;
      color: #4692E4 !important; }


form * {
  font-family: "Athiti", sans-serif; }



#privacy h1 {
  margin-bottom: 2rem; }

#privacy h2 {
  font-size: 1.5rem;
  margin: 2rem 0 1rem;
  text-transform: capitalize; }

#privacy h3 {
  font-size: 1.4rem;
  margin: 1rem 0 .5rem 0;
  text-transform: capitalize; }

#privacy p {
  margin-bottom: 1rem; }
  #privacy p:last-of-type {
    margin-bottom: 0; }

* {
  font-family: "Athiti", sans-serif; }

a {
  color: #4d5e65;
  font-family: "Athiti", sans-serif;
  text-decoration: underline; }
  a:hover {
    color: #E0297C;
    text-decoration: none; }

html, body {
  max-width: 100vw; }

.bg-primary {
  background-color: #E0297C !important; }

.btn {
  border-radius: .25rem;
  font-family: "Comfortaa", sans-serif;
  font-size: .9rem;
  padding: .5rem 4rem;
  text-decoration: none; }
  .btn.btn-primary {
    background-color: #E0297C !important;
    border-color: #E0297C !important; }
    .btn.btn-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:focus {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important; }
    .btn.btn-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important; }
  .btn.btn-outline-primary {
    background-color: transparent !important;
    border-color: #E0297C !important;
    color: #E0297C !important; }
    .btn.btn-outline-primary:active {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      box-shadow: none !important;
      color: #FFF !important; }
    .btn.btn-outline-primary:focus {
      background-color: transparent !important;
      border-color: #E0297C !important;
      box-shadow: none !important;
      color: #E0297C !important; }
    .btn.btn-outline-primary:hover {
      background-color: #bb1b64 !important;
      border-color: #bb1b64 !important;
      color: #FFF !important; }
  .btn.outline-secondary {
    background-color: transparent;
    border-color: #21282b;
    color: #21282b; }
    .btn.outline-secondary:hover {
      background-color: #21282b !important;
      color: #FFF; }
  .btn.btn-white {
    background-color: #FFF;
    border-color: #FFF;
    color: #E0297C; }
    .btn.btn-white:hover {
      background-color: #E0297C;
      border-color: #E0297C;
      color: #FFF; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #21282b;
  font-family: "Comfortaa", sans-serif;
  letter-spacing: -.1rem; }

p {
  color: #4d5e65;
  font-family: "Athiti", sans-serif; }

.text-primary {
  color: #E0297C !important; }

.bg-dark-gray {
  background-color: #21282b; }

.popup-overlay.background-blur {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); }

.text-yellow {
  color: #FDB515; }

.bg-yellow {
  background-color: #FDB515; }

.gradient-yellow {
  background: linear-gradient(to right, #FDB515, 75%, white); }

.ScrollUpButton__Container {
  pointer-events: none; }
  .ScrollUpButton__Container.ScrollUpButton__Toggled {
    pointer-events: all; }

div.wh-widget-send-button-wrapper.wh-widget-right div.wh-widget-send-button-wrapper-list a.wh-widget-send-button-get-button {
  display: none !important; }

.tour-card {
  display: block;
  margin-bottom: 1.5rem;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  position: relative;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #edf2f9;
  border-radius: .5rem;
  min-height: 440px; }

.tour-card:hover {
  box-shadow: 0 1rem 3rem rgba(18, 38, 63, 0.2);
  cursor: pointer; }

.tour-card.show-above:hover {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  cursor: auto; }

.tour-card-img-top {
  width: 100%;
  border-top-left-radius: calc(.5rem - 1px);
  border-top-right-radius: calc(.5rem - 1px); }

.tour-card-body {
  flex: 1 1 auto;
  padding: 1.5rem; }

.align-items-center {
  align-items: center !important; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.popup-overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 999; }

.show-above {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000;
  max-width: 600px;
  margin: 0 auto;
  max-height: 95%;
  margin-top: 1%; }

.show-above img {
  z-index: 100000;
  max-height: 280px;
  border-radius: .5rem .5rem 0 0;
  margin-bottom: 10px; }

.tour-card-sa-body {
  overflow-y: scroll;
  height: calc(100% - 290px);
  padding: 0 10px; }

.slide {
  opacity: 1;
  position: relative; }

.close-card {
  position: fixed;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  z-index: 100000; }

@media (max-width: 731px) {
  .tour-card {
    border-radius: 0rem; }
  .show-above {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    max-height: 100%; }
  .show-above img {
    z-index: 100000;
    max-height: 280px;
    border-radius: 0rem 0rem 0 0; }
  .tour-card-sa-body {
    overflow-y: scroll;
    height: 100%;
    padding: 0 10px;
    padding-bottom: 280px; } }

.ScrollUpButton__Container {
  position: fixed;
  top: 0;
  z-index: 100;
  opacity: 0;
  text-align: center;
  left: 0;
  right: 0;
  width: 250px;
  background: #E0297C;
  margin: 0 auto;
  padding: 5px 0px;
  border-radius: 0px 0px 5px 5px; }

.ScrollUpButton__Toggled {
  opacity: 1; }

#fullscreen-header {
  background-size: cover;
  background-repeat: no-repeat; }

.booking-form-fixed #bookingform {
  max-height: 70vh;
  overflow-y: scroll;
  position: -webkit-sticky;
  position: sticky;
  top: calc(50vh - 35vh + 54px); }

.tours-slider .slick-slide {
  width: 50vw; }

.tours-slider .controls .slick-prev::before, .tours-slider .controls .slick-next::before {
  color: #FDB515; }

.tours-slider .slick-list {
  display: flex;
  align-items: stretch; }
  .tours-slider .slick-list .slick-slide > div {
    height: 100%; }
    .tours-slider .slick-list .slick-slide > div > div {
      height: 100%; }
      .tours-slider .slick-list .slick-slide > div > div > div {
        height: 100%; }
        .tours-slider .slick-list .slick-slide > div > div > div .card-body {
          height: 100%; }

#destination .benefits > div:last-child {
  margin-bottom: 0 !important; }

#destination .steps .col-6:last-child .step {
  margin-bottom: 3rem !important; }

#destination .steps .col-6:nth-child(odd) .step {
  margin-right: 0.25rem; }

.dark-overlay {
  position: relative; }
  .dark-overlay .overlay-content {
    z-index: 2;
    position: relative; }
  .dark-overlay::before {
    content: '';
    position: absolute;
    display: block;
    background-color: black;
    opacity: 0.45;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

#tour-page-header {
  min-height: calc(100vh - 54px);
  background-size: cover;
  background-position: center; }
  #tour-page-header p.title {
    font-size: 2.5rem; }

.booking-form-fixed .tour-form {
  min-height: 300px;
  width: 100%;
  position: fixed;
  top: calc(50% + 54px);
  transform: translateY(-50%);
  max-width: 350px; }

.reviews-section {
  background-color: #dae0e5; }
  .reviews-section .carousel-item {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    display: block; }

.truncate-lines-2 {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical; }

.book-now {
  text-decoration: none;
  color: #E0297C;
  font-weight: bold; }

.slick-center {
  transform: scale(1.1);
  margin-left: 2rem;
  margin-right: 2rem;
  transition: .5s ease-in-out; }

.slick-slide {
  transition: .5s ease-in-out; }

#tours-page .tour-card-img-top img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

#tours-page .tour-card-item {
  cursor: pointer;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03); }
  #tours-page .tour-card-item:hover {
    box-shadow: 0 1rem 3rem rgba(18, 38, 63, 0.2); }
  #tours-page .tour-card-item .row {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0; }

#tours-page .tour-card-sa-body a {
  text-decoration: none;
  font-weight: bold; }

.slick-track {
  display: flex;
  align-items: center; }

.slick-slide {
  padding: 0 0.6rem; }

.close-card {
  cursor: pointer; }

.tour-form {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .tour-form .form-control {
    height: auto; }
  .tour-form .form-row {
    margin-bottom: 20px; }
    .tour-form .form-row select {
      -o-appearance: none;
      -ms-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    .tour-form .form-row .form-group {
      width: 100%;
      margin-bottom: 0; }
    .tour-form .form-row input, .tour-form .form-row select {
      width: 100%;
      padding: 12px;
      border: 1px solid #ced4da;
      border-radius: 4px; }
    .tour-form .form-row .select-wrapper::after {
      content: '';
      position: absolute;
      background: #fff url(/static/media/arrow_down.d1aad0b7.svg);
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 20px;
      top: 50%;
      right: 12px;
      transform: translateY(-50%); }
    .tour-form .form-row .select-wrapper {
      position: relative; }

.tour-card-item:last-child {
  margin-bottom: 0 !important; }

@media (min-width: 992px) {
  .fixed-form-container {
    position: absolute;
    top: calc(-3rem - 80vh + 54px);
    right: 0;
    height: calc(100% + 80vh + 3rem - 54px);
    z-index: 10; }
    .fixed-form-container .tour-form {
      position: -webkit-sticky;
      position: sticky;
      z-index: 10;
      top: calc(50vh - 233px + 54px); } }

.card-img-absolute {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center; }

