@import '../../assets/styles/_variables';

.header-new {
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .25), rgba(0, 0, 0, .25)), url('../../assets/images/video_sito_optimized.webp');
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  text-align: center;

  h1 {
    color: #FFF;
  }

  .lead {
    color: #FFF;
  }
}

.header {
  .header-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 4rem;
    }
  }

  .header-image {
    img {
      height: 500px;
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
  }
}

.usp {
  background-color: $colorLight;

  img {
    float: left;
    height: 100%;
    margin-right: 1.5rem;
    object-fit: contain;
    object-position: top;
    width: 75px;
    @media (max-width: 576px) {
      width: 50px;
      align-self: center;
    }
  }
}

.cities {
  text-align: center;

  .title {
    margin-bottom: 2rem;
  }

  img {
    border-radius: .4rem;
    height: 125px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

.counter {
  background-color: $colorLight;

  .count {
    color: $colorSecondary;
    font-family: $primaryFont !important;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }
}

.mockup-img {
  width: 74.6%;
  top: 6%;
  left: 13%;
}

.laptop{
  position: absolute;
  width:100%
}
.map{
  width:85%;
  margin:0 auto;
}
.laptop-container ,.laptop-container .col , .laptop-container .row{
  position: relative;
}
.laptop-container .col{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
@media (max-width: 731px){

  .header-new {
    min-height: 50vh;
    max-width: 100vw;
  }
  .h1, h1 {
    font-size:2rem;
  }
  .lead {
    font-size: 1rem;
  }
  .laptop{
    width:90%;
  }
  .map{
    width:75%;
  }
  .counter .row {
  flex-direction: column;
  }
}

.hero-background-video {
  position: absolute;
  top: 0;
  // width: 100%;
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hero-background-video {
  position: absolute;
  z-index: 0;
}

.z-index-4 {
  z-index: 4;
}

#homepage {
  max-width: 100vw;
  overflow-x: hidden;
}