@import './variables';

* {
  font-family: $secondaryFont;
}

a {
  color: $colorText;
  font-family: $secondaryFont;
  text-decoration: underline;

  &:hover {
    color: $colorSecondary;
    text-decoration: none;
  }
}

html, body {
  max-width: 100vw;
  // overflow-x: hidden;
}

.bg {
  &-primary {
    background-color: $colorSecondary !important;
  }
}

.btn {
  border-radius: .25rem;
  font-family: $primaryFont;
  font-size: .9rem;
  padding: .5rem 4rem;
  text-decoration: none;

  &.btn-primary {
    background-color: $colorSecondary !important;
    border-color: $colorSecondary !important;

    &:active {
      background-color: $colorSecondaryDarken !important;
      border-color: $colorSecondaryDarken !important;
      box-shadow: none !important;
    }

    &:focus {
      background-color: $colorSecondaryDarken !important;
      border-color: $colorSecondaryDarken !important;
      box-shadow: none !important;
    }

    &:hover {
      background-color: $colorSecondaryDarken !important;
      border-color: $colorSecondaryDarken !important;
    }
  }

  &.btn-outline-primary {
    background-color: transparent !important;
    border-color: $colorSecondary !important;
    color: $colorSecondary !important;

    &:active {
      background-color: $colorSecondaryDarken !important;
      border-color: $colorSecondaryDarken !important;
      box-shadow: none !important;
      color: #FFF !important;
    }

    &:focus {
      background-color: transparent !important;
      border-color: $colorSecondary !important;
      box-shadow: none !important;
      color: $colorSecondary !important;
    }

    &:hover {
      background-color: $colorSecondaryDarken !important;
      border-color: $colorSecondaryDarken !important;
      color: #FFF !important;
    }
  }

  &.outline-secondary {
    background-color: transparent;
    border-color: $colorDark;
    color: $colorDark;

    &:hover {
      background-color: $colorDark !important;
      color: #FFF;
    }
  }

  &.btn-white {
    background-color: #FFF;
    border-color: #FFF;
    color: $colorSecondary;

    &:hover {
      background-color: $colorSecondary;
      border-color: $colorSecondary;
      color: #FFF;
    }
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $colorDark;
  font-family: $primaryFont;
  letter-spacing: -.1rem;
}

p {
  color: $colorText;
  font-family: $secondaryFont;
}

.text {
  &-primary {
    color: $colorSecondary !important;
  }
}

.bg-dark-gray {
  background-color: $colorDark;
}

.popup-overlay.background-blur {
  backdrop-filter: blur(10px);
}

.text-yellow {
  color: $yellow;
}

.bg-yellow {
  background-color: $yellow;
}

.gradient-yellow {
  background: linear-gradient(to right, $yellow, 75%, white);
}

.ScrollUpButton__Container {
  pointer-events: none;
  &.ScrollUpButton__Toggled {
    pointer-events: all;
  }
}

div.wh-widget-send-button-wrapper.wh-widget-right div.wh-widget-send-button-wrapper-list a.wh-widget-send-button-get-button {
  display: none !important;
}