@import '../../assets/styles/_variables';

#partners {
  .list-items {
    text-align: center;

    .title {
      margin: 1.5rem 0 .5rem 0;
    }

    p {
      margin-bottom: 0;
    }

    img {
      display: block;
      height: 100px;
      margin: 0 auto;
      object-fit: cover;
      object-position: center;
      width: 100px;
    }
  }

  .nav.nav-tabs {
    border: 0;
    border-bottom: 1px solid $colorLight;
    justify-content: center;

    .nav-item {
      align-items: center;
      border: 1px solid $colorLight;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      height: 100px;
      justify-content: center;
      text-decoration: none;
      width: 200px;

      &:hover {
        background-color: $colorLight;
      }

      &.active {
        background-color: $colorLight !important;
        color: $colorSecondary !important;
      }
    }
  }
}

#partners-page, #about {
  img {
    max-width: 100%;
    width: 100%;
  }
  #bookingform {
    display: none;
  }
  .popup-overlay {
    backdrop-filter: blur(10px);
  }
  .fixed-button-container {
    position: fixed;
    bottom: 0;
    right: 100px;
    z-index: 100000000;
  }

  .btn {
    transition: 0.2s ease-in-out;
  }

  .btn-hidden {
    opacity: 0;
    transform: scale(0.25);
  }

  .partner-logo {
    max-width: 250px;
  }

  .integrations-logo {
    max-height: 100px;
    width: auto;
    max-width: 300px;
  }

  .partner-benefits {
    @media (max-width: 576px) {
      .partner-benefit:last-child {
        order: -1;
      }
    }
    img {
      max-width: 75px;
      @media (max-width: 576px) {
        width: 60px;
        align-self: center;
      }
    }
  }
}
